import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { Subheading, Header } from '../HomepageHero/styles'
import { Header as CounterHeader } from '../Counter/styles'
import { ShopPayImage } from '../temp/ToolTip/styles'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  gap: 2rem;
  padding: 2rem;
  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr;
    align-items: center;
    gap: 0px;
    padding: 0px;
  }
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const ImageContainer = styled.div`
  position: relative;
  @media (max-width: ${breakpoints.l}px) {
    margin-left: 5%;
  }
`
export const CtaContainer = styled.div`
  display: flex;
  padding-bottom: 48px;
  a {
    padding: 15px 48px;
  }
  @media (max-width: ${breakpoints.l}px) {
    justify-content: center;
    padding-bottom: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    a {
      width: 100%;
      text-align: center;
    }
  }
`
export const LogoImageContainer = styled.div`
  width: 100px;
  @media (max-width: ${breakpoints.l}px) {
    width: 70px;
  }
  @media (max-width: 365px) {
    margin: 0px -4px;
  }
`
export const SubHeading = styled(Subheading)`
  line-height: 150%;
  max-width: 600px;
  margin-bottom: 40px;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 8px 0px 0px;
  }
`
export const Heading = styled(Header)`
  max-width: 600px;
  margin-top: 8px;
  @media (max-width: ${breakpoints.l}px) {
    width: 370px;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 320px;
    margin: auto;
    max-width: 100%;
  }
`
export const SyncsHeader = styled(CounterHeader)`
  margin: 16px 0px;
  font-size: 24px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
    width: auto;
  }
`
export const SyncsSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.l}px) {
    justify-content: center;
    align-items: center;
  }
`
export const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.l}px) {
    gap: 16px;
    margin: 16px 0px 0px;
  }
`
export const ShopPayLogoContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  @media (max-width: ${breakpoints.xl}px) {
    bottom: 10px;
    left: auto;
    top: auto;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.55);
    border-radius: 24px;
    display: inline-block;
  }
  @media (max-width: ${breakpoints.l}px) {
    bottom: 10px;
    left: 10px;
    top: auto;
    right: auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    bottom: 10px;
    left: 10px;
    top: auto;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.55);
    border-radius: 16px;
    display: inline-block;
  }
  @media (max-width: ${breakpoints.s}px) {
    bottom: 0px;
    left: 0px;
    top: auto;
    right: 0px;
    border-radius: 0px;
  }
`
export const ShopPayLogo = styled(ShopPayImage)`
  @media (max-width: 1600px) {
    width: 240px;
    margin: 0px auto;
  }
  @media (max-width: ${breakpoints.xl}px) {
    width: 220px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 300px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 240px;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 180px;
  }
  @media (max-width: 460px) {
    width: 140px;
  }
`
